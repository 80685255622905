/* RELATED SECTION */

/* .brandsslider__related-carousel .flickity-button, .brandsslider__related-carousel .flickity-page-dots {
    visibility: hidden;
} */

.brandsslider__related-carousel .flickity-page-dots {
  bottom: -45px;
}
.brandsslider__related-cell a img {
  max-width: 90%;
  height: 495px;
  margin: 0 auto;
  display: block;
  object-fit: contain;
  object-position: center;
  justify-self: center;
}
.brandsslider__related-cell a {
  width: 100%;
  height: 100%;
  display: block;
}
.brandsslider__related-cell {
  width: 100%;
  height: auto;
  margin: 0% 2%;
  border-radius: 5px;
}

.brandsslider__related-item {
  display: grid;
  justify-content: center;
  align-items: center;
}
.brandsslider__related-item img {
  max-width: 280px;
  height: 400px;
  object-fit: contain;
  object-position: center;
  justify-self: center;
}
.brandsslider__related-wrapper {
  color: #ffffff;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 20px;
}

/* 768PX - TABLET AND UP */
@media only screen and (min-width: 768px) {
  .brandsslider__related-cell {
    width: 48%;
  }
  .brandsslider__related-item img {
    max-width: 330px;
    height: 515px;
    object-fit: contain;
    object-position: center;
    justify-self: center;
  }
}

/* 1024PX - SMALL LAPTOPS AND UP */
@media only screen and (min-width: 1024px) {
  .brandsslider__related-cell {
    width: 29.333%;
  }
}
