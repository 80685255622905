header {
  background: #000000;
  border-bottom: 1px solid #2a2a2a;
  background-position: 50% 10%;
  background-size: cover;
  background-repeat: no-repeat;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  position: relative;
  border-bottom: 1px solid #2a2a2a;
}
header div {
  color: #fff;
  display: grid;
  padding: 1rem;
}
.header__logo {
  padding-bottom: 0px;
}
.header__logo img,
.header__tagline p,
.header__the-lab img,
.header__diageo-menu-btn {
  opacity: 0;
  -webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
  -ms-transform: translateY(10px);
  -o-transform: translateY(10px);
  transform: translateY(10px);
}
.header__tagline {
  padding-top: 5px;
  padding-bottom: 0px;
}
.header__lab-logo {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  padding-top: 5px;
}
.header__tagline p {
  color: #c1c1c1;
  font-size: 12px;
  font-family: "MyriadBold", sans-serif;
  text-transform: uppercase;
  text-align: center;
  margin: 0 0 0rem 0;
  font-weight: bold;
}
.header__home-link,
.header__the-lab {
  text-align: center;
}
.header__logo-img {
  width: 310px;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  -o-transform: translateY(0px);
  transform: translateY(0px);
}

.header__lab-logo-img {
  justify-self: center;
  width: 170px;
  max-height: inherit;
  /* -webkit-transform: translateY(-6px);
    -moz-transform: translateY(-6px);
    -ms-transform: translateY(-6px);
    -o-transform: translateY(-6px);
    transform: translateY(-6px); */
}

/* MENU */
.header__diageo-menu {
  z-index: 111;
  padding: 0px;
}
.header__diageo-menu-btn.header__menu-active::before {
  opacity: 0;
}
.header__diageo-menu-btn.header__menu-active:hover span:nth-of-type(3),
.header__diageo-menu-btn.header__menu-active:hover span:nth-of-type(2) {
  width: 80%;
}
.header__diageo-menu-btn:hover span:nth-of-type(3) {
  width: 35%;
}
.header__diageo-menu-btn:hover span:nth-of-type(2) {
  width: 65%;
}
.header__diageo-menu-btn::before {
  background: transparent;
  opacity: 1;
  content: "";
  top: -16px;
  left: -12px;
  width: 50px;
  height: 50px;
  position: absolute;
  border-radius: 100%;
  border: 1px solid #ffffff;
  box-shadow: 1px 1px 10px rgb(0, 0, 0, 0.35);
  background: rgba(0, 0, 0, 0.7);
}

.header__diageo-menu-btn {
  position: fixed;
  top: unset;
  right: 22px;
  bottom: 27px;
  height: 27px;
  width: 30px;
  cursor: pointer;
  z-index: 100;
  /* opacity: 0; turn this back on with gsap enabled */
  -webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
  -ms-transform: translateY(10px);
  -o-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  -ms-transition: opacity 0.25s ease;
  -o-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}
.header__diageo-menu-btn.header__menu-active .header__menu-line-top {
  background: #fff;
  -webkit-transform: translateY(8px) translateX(0) rotate(45deg);
  -moz-transform: translateY(8px) translateX(0) rotate(45deg);
  -ms-transform: translateY(8px) translateX(0) rotate(45deg);
  -o-transform: translateY(8px) translateX(0) rotate(45deg);
  transform: translateY(8px) translateX(0) rotate(45deg);
}
.header__diageo-menu-btn.header__menu-active .header__menu-line-middle {
  opacity: 0;
  background: #fff;
}
.header__diageo-menu-btn.header__menu-active .header__menu-line-bottom {
  background: #fff;
  -webkit-transform: translateY(-8px) translateX(0) rotate(-45deg);
  -moz-transform: translateY(-8px) translateX(0) rotate(-45deg);
  -ms-transform: translateY(-8px) translateX(0) rotate(-45deg);
  -o-transform: translateY(-8px) translateX(0) rotate(-45deg);
  transform: translateY(-8px) translateX(0) rotate(-45deg);
}
.header__diageo-menu-btn span {
  background: #ffffff;
  border: none;
  height: 1px;
  width: 80%;
  position: absolute;
  top: 0;
  right: 16%;
  cursor: pointer;
  -webkit-transition: all 0.35s ease;
  -moz-transition: all 0.35s ease;
  -ms-transition: all 0.35s ease;
  -o-transition: all 0.35s ease;
  transition: all 0.35s ease;
}
.header__diageo-menu-btn span:nth-of-type(2) {
  top: 8px;
}
.header__diageo-menu-btn span:nth-of-type(3) {
  top: 16px;
}
.header__diageo-overlay {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  background: rgb(195, 0, 43);
  background: -moz-radial-gradient(
    circle,
    rgba(195, 0, 43, 0.95) 0%,
    rgba(126, 0, 28, 0.95) 100%
  );
  background: -webkit-radial-gradient(
    circle,
    rgba(195, 0, 43, 0.95) 0%,
    rgba(126, 0, 28, 0.95) 100%
  );
  background: radial-gradient(
    circle,
    rgba(195, 0, 43, 0.95) 0%,
    rgba(126, 0, 28, 0.95) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#c3002b",endColorstr="#7e001c",GradientType=1);
  -webkit-transition: opacity 0.35s, visibility 0.35s, height 0.35s;
  -moz-transition: opacity 0.35s, visibility 0.35s, height 0.35s;
  -ms-transition: opacity 0.35s, visibility 0.35s, height 0.35s;
  -o-transition: opacity 0.35s, visibility 0.35s, height 0.35s;
  transition: opacity 0.35s, visibility 0.35s, height 0.35s;
}
.header__diageo-overlay.header__menu-open {
  opacity: 1;
  visibility: visible;
  height: 100%;
}
.header__menu-login {
  position: absolute;
  right: 10px;
  top: 10px;
  padding: 0px;
  z-index: 11;
}
.header__diageo-overlay.header__menu-open li,
.header__diageo-overlay.header__menu-open label,
.header__diageo-overlay.header__menu-open
  .header__menu-login
  .header__menu-btn {
  -webkit-animation: fadeInRight 0.5s ease forwards;
  animation: fadeInRight 0.5s ease forwards;
  -webkit-animation-delay: 0.35s;
  animation-delay: 0.35s;
}
.header__diageo-overlay.header__menu-open li:nth-of-type(2) {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}
.header__diageo-overlay.header__menu-open li:nth-of-type(3) {
  -webkit-animation-delay: 0.45s;
  animation-delay: 0.45s;
}
.header__diageo-overlay.header__menu-open li:nth-of-type(4) {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}
.header__diageo-overlay.header__menu-open li:nth-of-type(5) {
  -webkit-animation-delay: 0.55s;
  animation-delay: 0.55s;
}
.header__diageo-overlay.header__menu-open li:nth-of-type(6) {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}
.header__diageo-overlay.header__menu-open li:nth-of-type(7) {
  -webkit-animation-delay: 0.65s;
  animation-delay: 0.65s;
}
.header__diageo-overlay.header__menu-open li:nth-of-type(8) {
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}
.header__diageo-overlay.header__menu-open li:nth-of-type(9) {
  -webkit-animation-delay: 0.75s;
  animation-delay: 0.75s;
}
.header__diageo-overlay.header__menu-open li:nth-of-type(10) {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}
.header__diageo-overlay.header__menu-open
  .header__menu-login
  .header__menu-btn {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}
.header__menu-social-icons a:hover i {
  -webkit-transform: translateY(-2px);
  -moz-transform: translateY(-2px);
  -ms-transform: translateY(-2px);
  -o-transform: translateY(-2px);
  transform: translateY(-2px);
}
.header__menu-social-icons a:hover {
  color: #81fffa;
}
.header__menu-social-icons a i,
.header__menu-social-icons a {
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  -o-transform: translateY(0px);
  transform: translateY(0px);
}
.menu-social-icons {
  display: flex;
  padding: 0rem;
  margin-top: 3rem;
}
.header__diageo-overlay ul li .header__menu-social-icons a {
  padding: 5px 10px;
  font-size: 20px;
  text-align: center;
}
.header__diageo-overlay ul li .header__menu-social-icons a::after {
  display: none;
}
.header__diageo-overlay ul li .header__menu-social-icons a i {
  display: block;
  margin-left: 0px;
}
.header__diageo-overlay nav {
  position: relative;
  font-family: "MyriadRegular", sans-serif;
  text-align: left;
  display: grid;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
.header__diageo-overlay ul {
  list-style: none;
  padding: 0;
  margin: 0 auto;
  display: inline-block;
  position: relative;
}
.header__diageo-overlay ul li,
.header__diageo-overlay ul label,
.header__diageo-overlay .header__menu-login .header__menu-btn {
  display: block;
  position: relative;
  opacity: 0;
}
.header__diageo-overlay ul li a i {
  margin-left: 10px;
  display: none;
}
.header__diageo-overlay ul li a:hover::after {
  background: #81fffa;
}
.header__diageo-overlay ul li a:hover {
  color: #81fffa;
}
.header__diageo-overlay ul li a {
  display: block;
  position: relative;
  color: #fff;
  text-decoration: none;
  overflow: hidden;
  font-size: 1.5rem;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.header__diageo-overlay ul li a:hover:after,
.header__diageo-overlay ul li a:focus:after,
.header__diageo-overlay ul li a:active:after {
  width: 100%;
}
.header__diageo-overlay ul li a:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0%;
  height: 1px;
  background: #fff;
  transition: 0.35s;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}
.header__menu-sso-btn {
  background: none;
  color: #ffffff;
  border: none;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
}

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    left: 20%;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    left: 20%;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}

/* 768PX - TABLET AND UP */
@media only screen and (min-width: 768px) {
  header {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .header__logo,
  .header__tagline {
    padding-bottom: 1rem;
    padding-top: 1rem;
  }
  .header__lab-logo-img {
    width: 210px;
    max-height: 55px;
  }
  .header__tagline p {
    font-size: 13px;
  }
  .header__lab-logo {
    display: grid;
    grid-template-columns: 1fr 80px;
    padding-top: 1rem;
  }
  .header__menu-login {
    right: 100px;
    top: 20px;
  }
  .header__diageo-overlay ul li a {
    font-size: 3rem;
  }
  .header__diageo-menu-btn {
    position: absolute;
    top: 35px;
    right: 31px;
    bottom: unset;
  }
  .header__home-link,
  .header__the-lab {
    text-align: center;
  }
  .header__logo-img {
    -webkit-transform: translateY(5px);
    -moz-transform: translateY(5px);
    -ms-transform: translateY(5px);
    -o-transform: translateY(5px);
    transform: translateY(5px);
  }
}
/* 1024PX - SMALLER LAPTOPS AND UP */
@media only screen and (min-width: 1024px) {
  .header__menu-login {
    right: 100px;
    top: 20px;
  }
  .header__diageo-overlay ul li a {
    font-size: 4rem;
  }
}
