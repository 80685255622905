/* FONTS
=================== */
@font-face {
  font-family: "MyriadLight";
  font-style: normal;
  font-stretch: normal;
  src: url('./fonts/MyriadLight/normal_normal.woff') format('woff'), url('./fonts/MyriadLight/normal_normal.woff2') format('woff2');
}
@font-face {
  font-family: "MyriadBold";
  font-style: normal;
  font-stretch: normal;
  src: url('./fonts/MyriadBold/normal_normal.woff') format('woff'), url('./fonts/MyriadBold/normal_normal.woff2') format('woff2');
}
@font-face {
  font-family: "MyriadRegular";
  font-style: normal;
  font-stretch: normal;
  src: url('./fonts/MyriadRegular/normal_normal.woff') format('woff'), url('./fonts/MyriadRegular/normal_normal.woff2') format('woff2');
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
