.style-guide-half {
  display: grid;
  grid-template-columns: minmax(auto, 850px);
  justify-content: center;
}
.style-guide-hero h1 {
  font-size: 2rem;
  font-family: "MyriadBold", sans-serif;
  line-height: normal;
}
.style-guide-hero {
  padding: 2rem !important;
}
.style-guide-hero h1,
.style-guide-hero p {
  opacity: 0;
  -webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
  -ms-transform: translateY(10px);
  -o-transform: translateY(10px);
  transform: translateY(10px);
}
.style-guide-page .half {
  padding: 0rem 3rem;
}
.style-guide-page .half:nth-child(2) .accordion-wrap {
  border-top: 1px solid #ffffff;
}
.accordion-wrap h2 {
  margin: 0 0 0rem 0 !important;
}
.style-guide-page h2 {
  font-size: 2rem;
  padding: 1rem 0rem;
  margin-bottom: 0rem;
  padding-right: 4.5rem;
}
/* 768PX - TABLET AND UP */
@media only screen and (min-width: 768px) {
  .style-guide-hero h1 {
    font-size: 4rem;
  }
  .style-guide-hero {
    padding: 3rem !important;
  }
}

/* 1024PX - SMALL LAPTOPS AND UP */
@media only screen and (min-width: 1024px) {
}
