/* BRAND HERO */
.brand__mobile-hero {
  display: block;
}
.brand__desktop-hero {
  display: none;
}
.brand__brand-hero {
  opacity: 0;
  -webkit-transform: translateY(1000px);
  -moz-transform: translateY(1000px);
  -ms-transform: translateY(1000px);
  -o-transform: translateY(1000px);
  transform: translateY(1000px);
}
.brand__play-btn {
  position: absolute;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  align-items: flex-end;
  padding: 2rem;
  bottom: 0rem;
  text-decoration: none;
  opacity: 0;
  -webkit-transform: translateY(-100px);
  -moz-transform: translateY(-100px);
  -ms-transform: translateY(-100px);
  -o-transform: translateY(-100px);
  transform: translateY(-100px);
}
.brand__play-btn .brand__icon {
  color: #ffffff;
  font-size: 2rem;
  border-radius: 100px;
  height: 80px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #ffffff;
  box-shadow: 1px 1px 31px rgb(0 0 0 / 45%);
}
.brand__hero {
  display: grid;
  position: relative;
  overflow: hidden;
  background: #000000;
}
.brand__hero-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* INTRO SECTION */
.brand__intro-copy {
  color: #b5b5b5;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 2rem;
}
.brand__intro-heading span {
  font-size: 2rem;
  font-family: "MyriadRegular", sans-serif;
}
.brand__intro-heading {
  margin-bottom: 2rem;
  font-family: "MyriadBold", sans-serif;
  font-size: 2.5rem;
}
.brand__intro-subtitle {
  font-family: "MyriadBold", sans-serif !important;
  font-size: 1.4rem !important;
  display: block;
}
.brand__intro-wrap {
  max-width: 768px;
  justify-self: center;
}
.brand__intro-wrap,
.brand__share-text,
.brand__video-download-heading,
.brand__video-item,
.brand__image-download-heading,
.brand__image-item,
.brand__hashtag-heading,
.brand__hashtag,
.brand__message-heading,
.brand__message,
.tips__heading,
.tips__block,
.brandsslider__related-heading,
.brandsslider__related-cell a,
.brandsslider__related .dil-btn {
  opacity: 0;
  -webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
  -ms-transform: translateY(10px);
  -o-transform: translateY(10px);
  transform: translateY(10px);
}

/* SHARE SECTION */
.brand__share-wrap a:hover i {
  -webkit-transform: translateY(-2px);
  -moz-transform: translateY(-2px);
  -ms-transform: translateY(-2px);
  -o-transform: translateY(-2px);
  transform: translateY(-2px);
}
.brand__share-wrap a:hover {
  opacity: 1;
}
.brand__share-wrap a {
  opacity: 0.8;
}
.brand__share-wrap a,
.brand__share-wrap a i {
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  -o-transform: translateY(0px);
  transform: translateY(0px);
}
.brand__share-wrap a:last-child {
  margin-right: 0rem;
}
.brand__share-wrap a {
  color: #ffffff;
  font-size: 2.5rem;
  margin-right: 2rem;
}
.brand__share-feature {
  display: none; /* hide the social icons on each brand page */
}
.brand__share-wrap {
  display: grid;
  grid-template-columns: 1fr;
  /* grid-template-columns: repeat(auto-fit, minmax(345px, 1fr)); set this if you want 2 columns */
  grid-column-gap: 2rem;
  align-items: center;
  text-align: center;
}
.brand__share-text::before {
  display: none;
  content: "";
  position: absolute;
  top: 0px;
  right: -20px;
  margin: auto;
  height: 100%;
  width: 1px;
  mix-blend-mode: difference;
  background: rgb(255 255 255 / 22%);
}
.brand__share-text h3 {
  font-family: "MyriadBold", sans-serif;
  text-align: left;
  font-size: 14px;
  font-size: 2.5rem;
  position: relative;
  margin-bottom: 0px;
  /* max-width: 500px; */
}
.brand__share-text {
  color: #ffffff;
  position: relative;
  text-transform: uppercase;
  /* margin-bottom: 2rem; */
  display: grid;
  justify-content: flex-start;
}
/* BRAND STYLE EXCEPTIONS */
.captain-morgan-vita-coco-page .brand__video-item img,
.captain-morgan-vita-coco-page .brand__image-item img {
  border: 1px solid #6288a7;
}
.buchanans-pineapple-page .brand__video-item img,
.buchanans-pineapple-page .brand__image-item img {
  border: 1px solid #d7b24f;
}
.buchanans-pineapple-page .brand__copy-block:hover h4,
.buchanans-pineapple-page .brand__copy-block:hover p,
.baileys-vanilla-mint-shake-page .brand__copy-block:hover p strong,
.baileys-vanilla-mint-shake-page .brand__copy-block:hover h4,
.baileys-vanilla-mint-shake-page .brand__copy-block:hover p {
  color: #ffffff !important;
}
.baileys-vanilla-mint-shake-page .brand__image-item img {
  border: 1px solid #5caba0;
}
.baileys-vanilla-mint-shake-page .brand__video-item img {
  border: 1px solid #65a685;
}

/* VIDEO DOWNLOAD SECTION */
#brand__video-downloads {
  overflow: hidden;
}
.brand__video-rollover:hover .brand__preview-wrap {
  visibility: visible !important;
}
.brand__preview-wrap {
  border: 1px solid #424242;
  visibility: hidden;
  position: fixed;
  background: #000000;
  backface-visibility: hidden;
  z-index: 9999999;
  pointer-events: none;
  /* pointer-events: none is needed */
  cursor: none;
}
.brand__video-item .brand__download-wrap a {
  display: flex;
}
.brand__video-item p em,
.brand__image-item p em {
  color: #919191;
}
.brand__video-item p {
  font-size: 1rem;
  text-align: center;
}
.brand__video-item img {
  max-width: 100%;
  height: 305px;
  object-fit: contain;
  width: 100%;
  border: 1px solid #424242;
  pointer-events: none;
  z-index: 0;
}
.brand__video-item .brand__video-rollover .brand__video-download-preview span {
  font-family: "MyriadRegular", sans-serif;
  font-size: 12px;
  text-transform: uppercase;
  margin-top: 10px;
}
.brand__video-item
  .brand__video-rollover
  .brand__video-item-btns
  a
  .brand__video-download-preview
  span {
  font-family: "MyriadBold", sans-serif;
}
.brand__video-item
  .brand__video-rollover
  .brand__video-item-btns
  a
  .brand__video-download-preview {
  display: flex;
  flex-flow: column;
  justify-content: center;
  text-align: center;
}
.brand__video-item .brand__video-rollover .brand__video-item-btns a {
  color: #ffffff;
  text-decoration: none;
}
.brand__video-item .brand__video-rollover:hover .brand__video-item-btns {
  opacity: 1;
}
.brand__video-item .brand__video-rollover .brand__video-item-btns {
  height: 100%;
  width: 100%;
  background: rgb(0 0 0 / 53%);
  position: absolute;
  left: 0;
  top: 0;
  font-size: 3rem;
  color: #ffffff;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
  opacity: 0;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.brand__video-item .brand__video-rollover {
  position: relative;
  margin-bottom: 10px;
  display: flex;
  cursor: pointer;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

/* REVEAL EFFECT */
.brand__video-reveal {
  position: fixed;
  width: 350px;
  height: 350px;
  top: 0;
  left: 0;
  pointer-events: none;
  opacity: 0;
  background: #000000;
  border: 1px solid #424242;
}
.brand__video-reveal-clip iframe {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.brand__video-reveal-inner,
.brand__video-reveal-clip {
  width: 100%;
  height: 100%;
  position: relative;
}
.brand__video-downloads-wrapper {
  color: #ffffff;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(241px, 1fr));
  gap: 20px;
}

/* IMAGE DOWNLOAD SECTION */
.brand__image-item a:hover .brand__image-download-preview {
  opacity: 1;
}
.brand__image-item a .brand__image-download-preview span {
  font-family: "MyriadRegular", sans-serif;
  font-size: 12px;
  text-transform: uppercase;
  margin-top: 10px;
}
.brand__image-item a .brand__image-download-preview span {
  font-family: "MyriadBold", sans-serif;
}
.brand__image-item a .brand__image-download-preview {
  height: 100%;
  width: 100%;
  background: rgb(0 0 0 / 53%);
  position: absolute;
  left: 0;
  top: 0;
  font-size: 3rem;
  flex-flow: column;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.brand__image-item .brand__download-wrap {
  margin-bottom: 10px;
  display: flex;
  position: relative;
}
.brand__image-item img {
  max-width: 100%;
  height: 305px;
  object-fit: contain;
  width: 100%;
  border: 1px solid #424242;
}
.brand__image-item p {
  font-size: 1rem;
  text-align: center;
}
.brand__image-downloads-wrapper {
  color: #ffffff;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(241px, 1fr));
  gap: 20px;
}

.brand__copy-block:hover .brand__copy-btn {
  opacity: 1;
}
.brand__copy-block .brand__copy-btn {
  opacity: 0;
  padding: 5px 10px;
  font-size: 12px;
  text-align: center;
  margin: 0 auto;
  display: block;
  color: #ffffff;
  background: transparent;
  border-radius: 5px;
  cursor: pointer;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.brand__copy-block .brand__copy-btn,
.brand__copy-block .brand__copy-btn:disabled,
.brand__copy-block .brand__copy-btn[disabled] {
  color: #ffffff;
  background: transparent;
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.5);
}
.brand__copy-block strong {
  padding: 8px 0px;
}
.brand__copy-block:hover {
  background: rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.5);
}
.brand__copy-block {
  border-radius: 5px;
  padding: 10px 0px;
  background: rgba(0, 0, 0, 0);
  border: 1px solid rgba(255, 255, 255, 0);
  border-radius: 5px;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

/* HASHTAG SECTION */
.brand__required-hashtag .brand__hashtag-wrapper {
  max-width: 480px;
  margin: 0 auto;
}
.brand__hashtag-row {
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 20px;
}
.brand__hashtag {
  text-align: center;
  margin-bottom: 1rem;
}
.brand__hashtag-wrapper {
  color: #ffffff;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 0px;
}
.brand__copy-feedback {
  margin-top: 0.5rem;
  visibility: hidden;
  display: block;
  text-align: center;
}

.brand__copy-feedback.brand__active {
  visibility: visible;
}

/* MESSAGE SECTION */
.brand__message p {
  color: #919191;
}
.brand__message {
  padding: 20px;
}
.brand__message h4 {
  margin-bottom: 10px;
}
.brand__message-wrapper {
  color: #ffffff;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 20px;
}

#video-downloads {
  overflow: hidden;
}
.video-rollover:hover .preview-wrap {
  visibility: visible;
}
.preview-wrap {
  border: 1px solid #424242;
  visibility: hidden;
  position: fixed;
  background: #000000;
  backface-visibility: hidden;
  z-index: 9999999;
  pointer-events: none;
  /* pointer-events: none is needed */
  cursor: none;
}
.video-item .download-wrap a {
  display: flex;
}
.video-item p em,
.image-item p em {
  color: #919191;
}
.video-item p {
  font-size: 1rem;
  text-align: center;
}
.video-item img {
  max-width: 100%;
  height: 305px;
  object-fit: contain;
  width: 100%;
  border: 1px solid #424242;
  pointer-events: none;
  z-index: 0;
}
.video-item .video-rollover i span {
  font-family: "MyriadRegular", sans-serif;
  font-size: 12px;
  text-transform: uppercase;
  margin-top: 10px;
}
.video-item .video-rollover .video-item-btns a i span {
  font-family: "MyriadBold", sans-serif;
}
.video-item .video-rollover .video-item-btns a i {
  display: flex;
  flex-flow: column;
  justify-content: center;
  text-align: center;
}
.video-item .video-rollover .video-item-btns a {
  color: #ffffff;
  text-decoration: none;
}
.video-item .video-rollover:hover .video-item-btns {
  opacity: 1;
}
.video-item .video-rollover .video-item-btns {
  height: 100%;
  width: 100%;
  background: rgb(0 0 0 / 53%);
  position: absolute;
  left: 0;
  top: 0;
  font-size: 3rem;
  color: #ffffff;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
  opacity: 0;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.video-item .video-rollover {
  position: relative;
  margin-bottom: 10px;
  display: flex;
  cursor: pointer;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

/* REVEAL EFFECT */
.video-reveal {
  position: fixed;
  width: 350px;
  height: 350px;
  top: 0;
  left: 0;
  pointer-events: none;
  opacity: 0;
  background: #000000;
  border: 1px solid #424242;
}
.video-reveal-clip iframe {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.video-reveal-inner,
.video-reveal-clip {
  width: 100%;
  height: 100%;
  position: relative;
}
.video-downloads-wrapper {
  color: #ffffff;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(241px, 1fr));
  gap: 20px;
}
.full.brandsslider__related.white-bg {
  padding: 2rem 0rem;
}

/* 768PX - TABLET AND UP */
@media only screen and (min-width: 768px) {
  .full.brandsslider__related.white-bg {
    padding: 5rem 2rem;
  }
  .brand__intro-heading {
    font-size: 2.8rem;
  }
  .brand__intro-copy {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .brand__mobile-hero {
    display: none;
  }
  .brand__desktop-hero {
    display: block;
  }
}
/* 1024PX - SMALL LAPTOPS AND UP */
@media only screen and (min-width: 1024px) {
}
