/* TIPS SECTION */
.tips__wrapper .tips__block:last-child a {
  padding: 0px 0px 20px 0px;
}
.tips__block button {
  cursor: pointer;
}
.tips__wrapper button:last-child {
  padding: 0px 0px 10px 0px;
}
.tips__wrapper button {
  padding: 20px 30px;
  color: #ffffff;
  font-weight: bold;
  background: none;
  border: none;
  text-align: center;
  font-size: 1rem;
  text-decoration: none;
  display: inline-block;
}
.tips__wrapper {
  padding: 20px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  flex-flow: column;
  text-align: center;
}
.tips__block strong {
  display: block;
  margin-bottom: 1rem;
}
.tips__block p {
  margin-bottom: 1rem;
}
.tips__wrapper a:last-child {
  padding: 0px 0px 10px 0px;
}

/* 768PX - TABLET AND UP */
@media only screen and (min-width: 768px) {
  .tips__wrapper {
    flex-flow: row;
  }
}
