/* HERO */
.home__index-hero {
  opacity: 0;
  -webkit-transform: translateX(-1000px);
  -moz-transform: translateX(-1000px);
  -ms-transform: translateX(-1000px);
  -o-transform: translateX(-1000px);
  transform: translateX(-1000px);
}

.home__hero {
  display: block;
  width: 100%;
  height: auto;
  position: relative;
}
.home__hero-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.home__mobile-hero {
  display: block;
}
.home__desktop-hero {
  display: none;
}
.home__hero-logo img {
  width: 80%;
  max-height: initial;
}
.home__home-lab-logo img {
  width: 46%;
  max-height: initial;
}
.home__home-lab-logo a {
  display: block;
  text-align: center;
}
.home__logo-hero-wrap .home__hero-tagline p {
  color: #c1c1c1;
  font-size: 12px;
  font-family: "MyriadBold", sans-serif;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  max-width: 200px;
  margin: 0 auto;
}
.home__logo-hero-wrap .home__hero-tagline {
  margin-bottom: 0.5rem;
}
.home__logo-hero-wrap .home__hero-logo {
  text-align: center;
  margin-bottom: 0.5rem;
}
.home__logo-hero-wrap .home__hero-lab-logo {
  display: grid;
}
.home__logo-hero-wrap .home__hero-lab-logo img {
  width: 345px;
  max-height: initial;
}
.home__logo-hero-wrap .home__logo img {
  width: 380px;
}
.home__logo-hero-wrap {
  grid-column: 2;
  justify-self: center;
  align-self: center;
  z-index: 1;
  -webkit-transform: translate(10%, -15%);
  -moz-transform: translate(10%, -15%);
  -ms-transform: translate(10%, -15%);
  -o-transform: translate(10%, -15%);
  transform: translate(10%, -15%);
}
.home__logo-hero {
  position: absolute;
  display: grid;
  width: 100%;
  height: 100%;
  z-index: 110;
  grid-template-columns: 1fr 1fr;
  border-bottom: 1px solid #2a2a2a;
}

/* CATALOG */
.home__related-wrapper {
  color: #ffffff;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 20px;
}
.home__related-item {
  display: grid;
  justify-content: center;
  align-items: center;
  cursor: not-allowed;
}
.home__related-item img {
  max-width: 330px;
  height: 515px;
  object-fit: contain;
  object-position: center;
  justify-self: center;
}
.home__hero-logo a,
.home__hero-tagline p,
.home__home-lab-logo a,
.home__related-item a {
  opacity: 0;
  -webkit-transform: translateY(20px);
  -moz-transform: translateY(20px);
  -ms-transform: translateY(20px);
  -o-transform: translateY(20px);
  transform: translateY(20px);
}
/* Hide HERO logo and tagline on mobile */
.home__hero-logo,
.home__hero-tagline,
.home__home-lab-logo {
  display: none;
}
/* 768PX - TABLET AND UP */
@media only screen and (min-width: 768px) {
  .home__mobile-hero {
    display: none;
  }
  .home__desktop-hero {
    display: block;
  }
  .home-page header {
    border-bottom: none;
  }
  /* hide HEADER tagline and logo on desktop */
  .home-page .header__tagline,
  .home-page .header__logo,
  .home-page .header__the-lab {
    display: none;
  }
  /* show HERO tagline and logo on desktop */
  .home__hero-logo,
  .home__hero-tagline,
  .home__home-lab-logo {
    display: grid;
  }
}
/* 1024PX - LAPTOP SIZES AND UP */
@media only screen and (min-width: 1024px) {
  .home__logo-hero-wrap .home__hero-logo,
  .home__logo-hero-wrap .home__hero-tagline {
    margin-bottom: 1rem;
  }
  .home__hero-logo img {
    width: 100%;
  }
  .home__logo-hero-wrap .home__hero-tagline p {
    font-size: 14px;
  }
  .home__home-lab-logo img {
    width: 60%;
  }
}
/* 1280PX - COMMON DESKTOP SIZES AND UP */
@media only screen and (min-width: 1280px) {
  .home__logo-hero-wrap {
    -webkit-transform: translate(20%, -25%);
    -moz-transform: translate(20%, -25%);
    -ms-transform: translate(20%, -25%);
    -o-transform: translate(20%, -25%);
    transform: translate(20%, -25%);
  }
  .home__logo-hero-wrap .home__hero-logo {
    margin-bottom: 2rem;
  }
  .home__hero-logo img {
    width: 430px;
    max-height: initial;
  }
  .home__logo-hero-wrap .home__hero-tagline {
    margin-bottom: 1.5rem;
  }
  .home__logo-hero-wrap .home__hero-tagline p {
    font-size: 1rem;
    max-width: inherit;
  }
  .home__home-lab-logo img {
    width: 300px;
  }
}
