footer {
  background: #000000;
  color: #797979;
}
.footer-links a,
.footer-links p {
  color: #797979;
  text-decoration: none;
  padding: 0px 30px 20px 0px;
  display: inline-block;
}
.footer-links {
  padding: 20px;
  display: inline;
  text-align: center;
  margin-top: 35px;
}
.footer__logos img {
  max-height: 60px;
}
.footer__logos {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(70px, 1fr));
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}
#dg_footer_wrapper {
  background: transparent;
}
#dg_footer_wrapper_inner ul li a,
#dg_footer_wrapper_inner ul li a:hover,
#dg_footer_wrapper_inner ul li:after,
#dg_footer_wrapper_inner ul li span {
  color: #ffffff !important;
  font-size: 14px !important;
  font-family: "MyriadRegular", sans-serif !important;
  text-decoration: none;
}
#dg_footer_wrapper_inner .dg_footer_item_last {
  float: none !important;
}
