/* RESETS 
=================== */
html {
  box-sizing: border-box;
  font-size: 16px;
  font-family: "MyriadRegular", sans-serif;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
body {
  background-color: #000000;
}
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  font-family: "MyriadRegular", sans-serif;
  color: #ffffff;
  word-break: break-word;
  margin: 0;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0 0 0.8rem 0;
}
h4 {
  font-size: 1.5rem;
}
ol li,
ul li {
  margin-bottom: 10px;
}
ol,
ul {
  margin-bottom: 1rem;
}
iframe {
  width: 100%;
}
img {
  max-width: 100%;
  height: auto;
}
/* GLOBALS
=================== */
.sso-btn:hover {
  background: #ff0069;
}
.sso-btn {
  background: #d8095e;
  color: #ffffff;
  width: 100%;
  border: none;
  padding: 10px;
  display: block;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
  font-family: "MyriadBold", sans-serif;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
body .reverse-btn {
  background-color: rgba(0, 0, 0, 0);
  border: solid 1px #000000;
  color: #000000;
}
body .reverse-btn::before {
  background-position: 280px -5px;
}
.dil-btn {
  display: inline-block;
  position: relative;
  text-decoration: none;
  text-align: center;
  padding: 13px 15px 13px 35px;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.3);
  border: solid 1px #c7c2ba;
  margin-top: 1rem;
  text-transform: uppercase;
  font-family: "MyriadLight", sans-serif;
}
.dil-btn::before {
  content: "";
  display: inline-block;
  background-image: url("./images/sprite.svg");
  position: absolute;
  top: 18px;
  left: 15px;
  width: 12px;
  height: 12px;
  background-position: -249.3px -66.2px;
  background-size: 265px 231px;
}
.disable-link {
  pointer-events: none;
  cursor: not-allowed;
}

.full {
  display: grid;
  grid-template-columns: minmax(auto, 1280px);
  justify-content: center;
  padding: 2rem 2rem;
  /* opacity: 0; turn this on with GSAP */
}
.half {
  display: grid;
  grid-template-columns: minmax(auto, 850px);
  justify-content: center;
  padding: 2rem 2rem;
}
.partial-op {
  opacity: 0.75;
}
.full-op {
  opacity: 1;
}
.heading {
  margin-bottom: 2rem;
  text-align: center;
  font-family: "MyriadBold", sans-serif;
  text-transform: uppercase;
}

/* COLORS */
.white {
  color: #ffffff;
}
.dark-blue {
  color: #001f46;
}
.turquoise {
  color: #29c3ae;
}
.pink {
  color: #febaa2;
}
.peach {
  color: #ff7e62;
}
.dark-yellow {
  color: #937e00;
}
.subtle-yellow {
  color: #c9c5ad !important;
}
.yellow-2 {
  color: #ffdb00 !important;
}
.orange {
  color: #ed531a;
}
.black {
  color: #000000;
}
.white {
  color: #ffffff !important;
}
.lime-green {
  color: #419f49;
}
.light-green {
  color: #89c6ad;
}
.green {
  color: #335e37;
}
.gold {
  color: #d49f27;
}
.yellow {
  color: #e3d678;
}
.tan {
  color: #dfb871;
}
/* SMIRNOFF BLUE RASPBERRY LEMONADE COLORS */
.smirnoff-magenta {
  color: #ff3764;
}
.smirnoff-yellow {
  color: #fbc31b;
}
.smirnoff-light-blue {
  color: #58beed !important;
}
.smirnoff-dark-blue {
  color: #02559e !important;
}
.smirnoff-light-blue-bg {
  background: #5cc2e3;
}
.smirnoff-blue-bg {
  background: #02559e;
}
.smirnoff-dark-blue-bg {
  background: #043357;
}

/* CAPTAIN MORGAN VITA COCO COLORS */
.captain-brown {
  color: #83634c;
}
.captain-red {
  color: #ff493b;
}
.captain-yellow {
  color: #fcc548;
}
.captain-green {
  color: #93d365;
}
.captain-dark-blue-2 {
  color: #5666b0 !important;
}
.captain-dark-blue {
  color: #1a2660 !important;
}
.captain-blue-2 {
  color: #61afd8;
}
.captain-blue {
  color: #93c2e8;
}
.captain-dark-blue-bg {
  background: #1a2660;
}
.captain-blue-bg {
  background: #93c2e8;
}
.captain-blue-bg-2 {
  background: #61afd8;
}

/* BUCHANANS COLORS */
.buchanan-dark-yellow {
  color: #db711f;
}
.buchanan-light-green {
  color: #63923f;
}
.buchanan-green {
  color: #06492d;
}
.buchanan-yellow {
  color: #ffc425;
}
.buchanan-green {
  color: #06492d !important;
}
.buchanan-light-yellow-bg {
  background: #fed462;
}
.buchanan-yellow-bg {
  background: #ffc425;
}

/* BAILEYS MINT COLORS */
.baileys-brown {
  color: #855349;
}
.baileys-mint-bg {
  background: #daffec;
}
.baileys-dark-mint {
  color: #038e70;
}
.baileys-mint {
  color: #6bb6ab !important;
}
.baileys-pink {
  color: #c14291;
}
.baileys-mint-alt-bg-3 {
  background-color: #6bc1b5;
}
.baileys-mint-alt-bg-2 {
  background-color: #78d7c9;
}
.baileys-mint-alt-bg {
  background-color: #7fcca5;
}
.baileys-mint-alt {
  color: #008c88 !important;
}
.baileys-green-bg {
  background-color: #9ce1be;
}
/* BAILEYS SMORES COLORS */
.baileys-cream {
  color: #e9ccb6 !important;
}
.baileys-cream-2 {
  color: #d8cabf !important;
}
.baileys-dark-brown {
  color: #57231a;
}
.baileys-graham {
  color: #cc6b28 !important;
}
.baileys-gray {
  color: #907e71 !important;
}
.baileys-cream-bg {
  background-color: #ead7c7;
}
.baileys-dark-teal {
  color: #149c89 !important;
}
.baileys-teal {
  color: #51c1b1;
}
.baileys-brown-bg {
  background-color: #86573e;
}
.baileys-dark-brown-bg {
  background-color: #57231a;
}
.baileys-dark-brown-bg-2 {
  background-color: #5c4533;
}
/* ICON SVG STYLES */
.yellow-svg-icon svg {
  color: #ffdb00 !important;
}
.yellow-svg-border {
  border: 2px solid #ffdb00 !important;
}
/* BACKGROUNDS */
.white-bg {
  background: #ffffff;
}
.dark-blue-bg {
  background: #001f46;
}
.orange-bg {
  background: #ed531a;
}
.dark-gray-bg {
  background: #181818;
}
.gray-bg {
  background: #232323;
}
.white-bg {
  background: #ffffff;
}
.black-bg {
  background: #000000;
}

/* GRADIENT BACKGROUNDS */
.red-gradient-bg {
  background: rgb(221, 49, 31);
  background: -moz-linear-gradient(
    231deg,
    rgba(221, 49, 31, 1) 0%,
    rgba(255, 78, 60, 1) 50%,
    rgba(221, 49, 31, 1) 100%
  );
  background: -webkit-linear-gradient(
    231deg,
    rgba(221, 49, 31, 1) 0%,
    rgba(255, 78, 60, 1) 50%,
    rgba(221, 49, 31, 1) 100%
  );
  background: linear-gradient(
    231deg,
    rgba(221, 49, 31, 1) 0%,
    rgba(255, 78, 60, 1) 50%,
    rgba(221, 49, 31, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#dd311f",endColorstr="#dd311f",GradientType=1);
}
.yellow-gradient-bg-2 {
  background: rgb(250, 195, 57);
  background: -moz-linear-gradient(
    231deg,
    rgba(250, 195, 57, 1) 0%,
    rgba(255, 213, 119, 1) 50%,
    rgba(250, 195, 57, 1) 100%
  );
  background: -webkit-linear-gradient(
    231deg,
    rgba(250, 195, 57, 1) 0%,
    rgba(255, 213, 119, 1) 50%,
    rgba(250, 195, 57, 1) 100%
  );
  background: linear-gradient(
    231deg,
    rgba(250, 195, 57, 1) 0%,
    rgba(255, 213, 119, 1) 50%,
    rgba(250, 195, 57, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fac339",endColorstr="#fac339",GradientType=1);
}
.peach-gradient-bg {
  background: rgb(251, 62, 84);
  background: -moz-linear-gradient(
    59deg,
    rgba(251, 62, 84, 1) 0%,
    rgba(253, 153, 124, 1) 50%,
    rgba(209, 55, 40, 1) 100%
  );
  background: -webkit-linear-gradient(
    59deg,
    rgba(251, 62, 84, 1) 0%,
    rgba(253, 153, 124, 1) 50%,
    rgba(209, 55, 40, 1) 100%
  );
  background: linear-gradient(
    59deg,
    rgba(251, 62, 84, 1) 0%,
    rgba(253, 153, 124, 1) 50%,
    rgba(209, 55, 40, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fb3e54", endColorstr="#d13728", GradientType=1);
}
.yellow-gradient-bg {
  background: rgb(241, 213, 43);
  background: -moz-linear-gradient(
    59deg,
    rgba(241, 213, 43, 1) 0%,
    rgba(255, 233, 97, 1) 50%,
    rgba(250, 224, 66, 1) 100%
  );
  background: -webkit-linear-gradient(
    59deg,
    rgba(241, 213, 43, 1) 0%,
    rgba(255, 233, 97, 1) 50%,
    rgba(250, 224, 66, 1) 100%
  );
  background: linear-gradient(
    59deg,
    rgba(241, 213, 43, 1) 0%,
    rgba(255, 233, 97, 1) 50%,
    rgba(250, 224, 66, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f1d52b", endColorstr="#fae042", GradientType=1);
}
.lime-green-gradient-bg {
  background: rgb(49, 202, 109);
  background: -moz-linear-gradient(
    231deg,
    rgba(49, 202, 109, 1) 0%,
    rgba(143, 210, 94, 1) 50%,
    rgba(49, 202, 109, 1) 100%
  );
  background: -webkit-linear-gradient(
    231deg,
    rgba(49, 202, 109, 1) 0%,
    rgba(143, 210, 94, 1) 50%,
    rgba(49, 202, 109, 1) 100%
  );
  background: linear-gradient(
    231deg,
    rgba(49, 202, 109, 1) 0%,
    rgba(143, 210, 94, 1) 50%,
    rgba(49, 202, 109, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#31ca6d",endColorstr="#31ca6d",GradientType=1);
}
.light-blue-gradient-bg {
  background: rgb(102, 179, 221);
  background: -moz-linear-gradient(
    231deg,
    rgba(102, 179, 221, 1) 0%,
    rgba(149, 196, 234, 1) 50%,
    rgba(102, 179, 221, 1) 100%
  );
  background: -webkit-linear-gradient(
    231deg,
    rgba(102, 179, 221, 1) 0%,
    rgba(149, 196, 234, 1) 50%,
    rgba(102, 179, 221, 1) 100%
  );
  background: linear-gradient(
    231deg,
    rgba(102, 179, 221, 1) 0%,
    rgba(149, 196, 234, 1) 50%,
    rgba(102, 179, 221, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#66b3dd",endColorstr="#66b3dd",GradientType=1);
}
.green-gradient-bg-2 {
  background: rgb(14, 135, 25);
  background: -moz-linear-gradient(
    59deg,
    rgba(14, 135, 25, 1) 0%,
    rgba(110, 189, 116, 1) 50%,
    rgba(14, 135, 25, 1) 100%
  );
  background: -webkit-linear-gradient(
    59deg,
    rgba(14, 135, 25, 1) 0%,
    rgba(110, 189, 116, 1) 50%,
    rgba(14, 135, 25, 1) 100%
  );
  background: linear-gradient(
    59deg,
    rgba(14, 135, 25, 1) 0%,
    rgba(110, 189, 116, 1) 50%,
    rgba(14, 135, 25, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0e8719", endColorstr="#0e8719", GradientType=1);
}
.mint-gradient-bg {
  background: rgb(71, 187, 171);
  background: -moz-linear-gradient(
    231deg,
    rgba(71, 187, 171, 1) 0%,
    rgba(102, 233, 214, 1) 50%,
    rgba(37, 147, 132, 1) 100%
  );
  background: -webkit-linear-gradient(
    231deg,
    rgba(71, 187, 171, 1) 0%,
    rgba(102, 233, 214, 1) 50%,
    rgba(37, 147, 132, 1) 100%
  );
  background: linear-gradient(
    231deg,
    rgba(71, 187, 171, 1) 0%,
    rgba(102, 233, 214, 1) 50%,
    rgba(37, 147, 132, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#47bbab",endColorstr="#259384",GradientType=1);
}
.green-gradient-bg-3 {
  background: rgb(6, 73, 45);
  background: -moz-linear-gradient(
    231deg,
    rgba(6, 73, 45, 1) 0%,
    rgba(14, 164, 101, 1) 50%,
    rgba(6, 73, 45, 1) 100%
  );
  background: -webkit-linear-gradient(
    231deg,
    rgba(6, 73, 45, 1) 0%,
    rgba(14, 164, 101, 1) 50%,
    rgba(6, 73, 45, 1) 100%
  );
  background: linear-gradient(
    231deg,
    rgba(6, 73, 45, 1) 0%,
    rgba(14, 164, 101, 1) 50%,
    rgba(6, 73, 45, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#06492d",endColorstr="#06492d",GradientType=1);
}
.teal-gradient-bg {
  background: rgb(71, 187, 171);
  background: -moz-linear-gradient(
    231deg,
    rgba(71, 187, 171, 1) 0%,
    rgba(102, 233, 214, 1) 50%,
    rgba(37, 147, 132, 1) 100%
  );
  background: -webkit-linear-gradient(
    231deg,
    rgba(71, 187, 171, 1) 0%,
    rgba(102, 233, 214, 1) 50%,
    rgba(37, 147, 132, 1) 100%
  );
  background: linear-gradient(
    231deg,
    rgba(71, 187, 171, 1) 0%,
    rgba(102, 233, 214, 1) 50%,
    rgba(37, 147, 132, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#47bbab",endColorstr="#259384",GradientType=1);
}
.dark-blue-gradient-bg {
  background: rgb(2, 121, 175);
  background: -moz-linear-gradient(
    231deg,
    rgba(2, 121, 175, 1) 0%,
    rgba(0, 179, 250, 1) 50%,
    rgba(2, 121, 175, 1) 100%
  );
  background: -webkit-linear-gradient(
    231deg,
    rgba(2, 121, 175, 1) 0%,
    rgba(0, 179, 250, 1) 50%,
    rgba(2, 121, 175, 1) 100%
  );
  background: linear-gradient(
    231deg,
    rgba(2, 121, 175, 1) 0%,
    rgba(0, 179, 250, 1) 50%,
    rgba(2, 121, 175, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0279af",endColorstr="#0279af",GradientType=1);
}
.blue-gradient-bg {
  background: rgb(1, 59, 93);
  background: -moz-linear-gradient(
    231deg,
    rgba(1, 59, 93, 1) 0%,
    rgba(55, 137, 196, 1) 50%,
    rgba(1, 59, 93, 1) 100%
  );
  background: -webkit-linear-gradient(
    231deg,
    rgba(1, 59, 93, 1) 0%,
    rgba(55, 137, 196, 1) 50%,
    rgba(1, 59, 93, 1) 100%
  );
  background: linear-gradient(
    231deg,
    rgba(1, 59, 93, 1) 0%,
    rgba(55, 137, 196, 1) 50%,
    rgba(1, 59, 93, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#013b5d",endColorstr="#013b5d",GradientType=1);
}
.orange-gradient-bg {
  background: rgb(213, 61, 6);
  background: -moz-linear-gradient(
    59deg,
    rgba(213, 61, 6, 1) 0%,
    rgba(255, 99, 43, 1) 50%,
    rgba(213, 61, 6, 1) 100%
  );
  background: -webkit-linear-gradient(
    59deg,
    rgba(213, 61, 6, 1) 0%,
    rgba(255, 99, 43, 1) 50%,
    rgba(213, 61, 6, 1) 100%
  );
  background: linear-gradient(
    59deg,
    rgba(213, 61, 6, 1) 0%,
    rgba(255, 99, 43, 1) 50%,
    rgba(213, 61, 6, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d53d06", endColorstr="#d53d06", GradientType=1);
}
.green-gradient-bg {
  background: rgb(0, 46, 18);
  background: -moz-linear-gradient(
    59deg,
    rgba(0, 46, 18, 1) 0%,
    rgba(23, 93, 59, 1) 50%,
    rgba(0, 46, 18, 1) 100%
  );
  background: -webkit-linear-gradient(
    59deg,
    rgba(0, 46, 18, 1) 0%,
    rgba(23, 93, 59, 1) 50%,
    rgba(0, 46, 18, 1) 100%
  );
  background: linear-gradient(
    59deg,
    rgba(0, 46, 18, 1) 0%,
    rgba(23, 93, 59, 1) 50%,
    rgba(0, 46, 18, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#002e12", endColorstr="#002e12", GradientType=1);
}

/* 768PX - TABLET AND UP */
@media only screen and (min-width: 768px) {
  .full {
    padding: 5rem 2rem;
    /* opacity: 0; turn this on with GSAP */
  }
  .half {
    padding: 5rem 2rem;
  }
}
