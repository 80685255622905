/* ACCORDION CSS STYLING GOES HERE */
.accordion__trigger {
  display: grid;
  grid-template-columns: 1fr 50px;
}
.accordion__container {
  display: grid;
  grid-template-columns: minmax(auto, 850px);
  justify-content: center;
  padding: 0rem 2rem;
  opacity: 0;
  -webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
  -ms-transform: translateY(10px);
  -o-transform: translateY(10px);
  transform: translateY(10px);
}
.accordion {
  border-bottom: 1px solid #ffffff;
  position: relative;
  color: #444;
  cursor: pointer;
  padding: 1rem 0rem;
  width: 100%;
  text-align: left;
  transition: 0.4s;
}
.accordion__content a {
  color: #ffffff;
}
.accordion__content {
  padding: 1rem 0rem 1rem 0rem;
}
.accordion__title {
  display: grid;
  color: #ffffff;
  font-size: 1.5rem;
  align-items: center;
}
.accordion__icon-indicator {
  color: #ffffff;
  font-size: 2rem;
  display: grid;
  justify-content: center;
  align-items: center;
}

/* 768PX - TABLET AND UP */
@media only screen and (min-width: 768px) {
  .accordion__container {
    padding: 0rem 3rem;
  }
}

/* 1024PX - SMALL LAPTOPS AND UP */
@media only screen and (min-width: 1024px) {
  .accordion__container {
    padding: 0rem;
  }
}
