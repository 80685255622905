.sso-box {
    height: 700px;
    display: grid;
    align-content: center;
}
.sso-wrap {
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
    background: #000000;
    border: 1px solid white;
    padding: 3rem;
}
.sso-wrap h1 {
    font-size: 1.6rem;
    font-family: "MyriadBold",sans-serif;
    color: #ffffff;
}
.sso-help{
    margin-top: 1.5rem;
    text-align: center;
}

.sso-help a {
    font-size: 1rem;
    text-decoration: none;
}